<template>
	<div class="main-wrapper mt-4 mb-3">
		<CRow class="mb-5">
			<CCol md="12">
				<CInput
					v-model="$v.formData.name.$model"
					label="Notification Name*"
					maxlength="255"
					:is-valid="!$v.formData.name.$error && null"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model="$v.formData.url.$model"
					label="URL*"
					maxlength="255"
					:is-valid="!$v.formData.url.$error && null"
					:invalid-feedback="$t('global.error.url')"
					:description="'Allow only url. example: https://www.studio7thailand.com'"
				/>
			</CCol>
			<CCol md="12">
				<CInput
					v-model="formData.messageTitle"
					label="Message title"
				/>
			</CCol>
			<CCol md="12">
				<CTextarea
					v-model="$v.formData.messageBody.$model"
					label="Message body*"
					rows="3"
					:is-valid="!$v.formData.messageBody.$error && null"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="12">
				<UploadLogo
					:logo.sync="formData.media.$modal"
					title="Notification Image"
				/>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="$v.formData.sendDate.$model"
					label="Send date*"
					:is-valid="!$v.formData.sendDate.$error"
					:invalid-feedback="$t('global.error.required')"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="2">
				<BaseInputTime
					v-model="$v.formData.sendTime.$model"
					label="Send time*"
					:is-valid="!$v.formData.sendTime.$error"
					:invalid-feedback="$t('global.error.required')"
				/>
			</CCol>
			<CCol md="12">
				<CRow>
					<CCol md="6">
						<small class="form-text text-muted w-100">If edit date/time to be the date that already passed. The notification will still send on the next interval. Please remove notification if you don't want to send</small>
					</CCol>
				</CRow>
			</CCol>
			<!-- Customer group -->
			<CCol md="12">
				<div class="form-group mt-5">
					<BundleSetCustomerGroup
						:customer-groups.sync="formData.customerGroups"
					/>
				</div>
			</CCol>
			<CCol md="12">
				<PromotionCustomerCondition
					:default-data="formData.customerCondition"
					custom-rule
					@onUpdate="handleCustomerConditionChange"
				/>
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:is-edit="isEdit"
			:disabled-confirm="isSubmitting || $v.$invalid || formData.isSent"
			:button-edit-confirm-text="isSubmitting ? 'Saving...' : 'Save changes'"
			:remove-text="isEdit ? 'Remove' : ''"
			content-class="main-wrapper"
			data-test-id="save-change-pickup-at-store"
			@onConfirm="handleSubmit"
			@onCancel="$router.push(routeNotification)"
			@onRemove="$refs['modal-remove'].open()"
		/>
		<BaseModalConfirmDelete
			ref="modal-remove"
			:handle-remove="deleteNotification.bind(null, formData.id)"
			description="Are you sure you want to delete this notification?"
			title="Remove this notification?"
			@onSuccess="$router.push(routeNotification)"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { required, maxLength, url } from 'vuelidate/lib/validators';
import BundleSetCustomerGroup from '@/components/BundleSetCustomerGroup.vue';
import PromotionCustomerCondition from '@/components/PromotionCustomerCondition.vue';
import BaseActionPanelStickyFooter from '@/components/BaseActionPanelStickyFooter.vue';
import BaseModalConfirmDelete from '@/components/BaseModalConfirmDelete.vue';
import UploadLogo from '@/components/UploadLogo.vue';
import { ROUTE_NAME } from '../enums/route';
import { scrollToTop } from '../assets/js/helpers';
import { timeFormat } from '../assets/js/validators';

export default {
	name: 'FormMobileNotification',

	components: {
		UploadLogo,
		BundleSetCustomerGroup,
		PromotionCustomerCondition,
		BaseActionPanelStickyFooter,
		BaseModalConfirmDelete,
	},

	validations: {
		formData: {
			name: {
				required,
				maxLength: maxLength(255),
			},
			url: {
				maxLength: maxLength(255),
				required,
				url,
			},
			messageTitle: {},
			messageBody: {
				required,
			},
			sendDate: {
				required,
			},
			sendTime: {
				required,
				timeFormat,
			},
			media: {},
			customerGroups: {
				required,
			},
			customerCondition: {},
		},
	},
	props: {
		isEdit: {
			type: Boolean,
			default: false,
		},
		isSubmitting: {
			type: Boolean,
			default: false,
		},
		form: {
			type: Object,
			default: () => (
				{
					name: null,
					url: null,
					messageTitle: null,
					messageBody: null,
					media: {},
					sendDate: null,
					sendTime: null,
					customerGroups: [],
					customerCondition: null,
				}
			),
		},
	},
	data() {
		return {
			formData: { },
			routeNotification: {
				name: ROUTE_NAME.MOBILE_NOTIFICATION,
			},
		};
	},
	created() {
		this.formData = this.form;
	},
	methods: {
		...mapActions({
			deleteNotification: 'mobileNotification/deleteNotification',
		}),
		handleCancel() {
			this.$router.push(this.routeNotification);
		},
		handleSubmit() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}
			this.$emit('onConfirm', this.formData);
		},
		handleRemove() {
			this.$emit('onRemove');
		},
		handleCustomerConditionChange(condition) {
			const { data } = condition;
			this.formData.customerCondition = data || {}; // {} is use for trigger watching method
		},
	},
};
</script>
