<template>
	<div class="container-fluid">
		<FormMobileNotification
			:is-submitting="create.isCreating"
			@onConfirm="handleCreate"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormMobileNotification from '@/components/FormMobileNotification.vue';

export default {
	name: 'MobileNotificationCreate',
	components: {
		FormMobileNotification,
	},
	computed: {
		...mapState('mobileNotification', {
			create: 'create',
		}),
	},
	methods: {
		...mapActions({
			createNotification: 'mobileNotification/createNotification',
		}),
		async handleCreate(formData) {
			await this.createNotification(formData);
		},
	},
};
</script>
